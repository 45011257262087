/* Framework and External Imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');
@import '~react-image-crop/dist/ReactCrop.css';

/* BASE STYLES */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ROOT AND VARIABLES */
/* These variables are used in the tailwind.config.js file where all classes and variants are set up */
:root {
  --cyan: #06b6d4;
  --cyan-light: #7addee;
  --cyan-dark: #155e75;
  --cyan-alternative: #579cb3;

  --blue: /* missing blue base color */ ;
  --blue-light: #c2e4f0;
  --blue-dark: #2a4365;
  --blue-alternative: /* missing blue alternative color */ ;

  --green: #7bbaa2;
  --green-light: #a7ddc8;
  --green-dark: /* missing green dark color */ ;
  --green-alternative: /* missing green alternative color */ ;

  --yellow: #eacc81;
  --yellow-light: #fef08a;
  --yellow-dark: #854d0e;
  --yellow-alternative: /* missing yellow alternative color */ ;

  --purple: #c89be1;
  --purple-light: #dfc1f1;
  --purple-dark: #5a417c;
  --purple-alternative: /* missing purple alternative color */ ;

  --red: #ff5555;
  --red-light: #ffb0b0;
  --red-dark: #8c1b1b;
  --red-alternative: /* missing red alternative color */ ;

  --gray: #edeae8;
  --gray-light: #f7f6f6;
  --gray-dark: /* missing gray dark color */ ;
  --gray-alternative: /* missing gray alternative color */ ;
}
/* CUSTOM CLASSES FOR REGULAR TEXT */
@layer components {
  .text {
    @apply text-zinc-800;
  }

  .text-disabled,
  .text-light {
    @apply text-zinc-500;
  }

  .text-placeholder {
    @apply text-gray-400;
  }
}

/* GENERAL CUSTOM STYLINGS */

.border {
  border: 1px #edeae8 solid;
}

.bg-root {
  background-color: #f7f6f6;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 6px !important;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.horizontal-scroll::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: transparent;
}
.horizontal-scroll::-webkit-scrollbar-thumb:horizontal {
  @apply bg-gray-300;
  border-radius: 10px;
}
textarea::-webkit-scrollbar {
  width: 1em;
}
textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* Drag dots - for adding drag-n-drop icon to draggable elements  */
.drag-dots::after {
  content: url('data:image/svg+xml;utf8,<svg viewBox="11.132 5.132 5.736 9.736" width="6" height="12" xmlns="http://www.w3.org/2000/svg"> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(140, 140, 140);" cx="12" cy="6" rx="0.868" ry="0.868" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(140, 140, 140);" cx="16" rx="0.868" ry="0.868" cy="6" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(140, 140, 140);" cx="12" cy="10" rx="0.868" ry="0.868" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(140, 140, 140);" cx="16" rx="0.868" ry="0.868" cy="10" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(140, 140, 140);" cx="12" cy="14" rx="0.868" ry="0.868" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(140, 140, 140);" cx="16" rx="0.868" ry="0.868" cy="14" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> </svg>');
  position: absolute;
  top: calc(50% - 12px);
  bottom: 0;
  left: 7px;
  z-index: 10;
  cursor: move;
}

.drag-dots:hover::after {
  content: url('data:image/svg+xml;utf8,<svg viewBox="11.132 5.132 5.736 9.736" width="6" height="12" xmlns="http://www.w3.org/2000/svg"> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(6 182 212);" cx="12" cy="6" rx="0.868" ry="0.868" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(6 182 212);" cx="16" rx="0.868" ry="0.868" cy="6" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(6 182 212);" cx="12" cy="10" rx="0.868" ry="0.868" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(6 182 212);" cx="16" rx="0.868" ry="0.868" cy="10" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(6 182 212);" cx="12" cy="14" rx="0.868" ry="0.868" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> <ellipse style="stroke-width: 1px; paint-order: fill; fill-rule: nonzero; stroke: none; fill: rgb(6 182 212);" cx="16" rx="0.868" ry="0.868" cy="14" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -2.220446049250313e-16)"/> </svg>');
}

.triangle-down::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #7addee;
}

/* Arrow icons */
.arrow-up:after {
  content: '\25bc';
  transform: rotate(180deg);
  color: var(--green);
  @apply ml-2 inline-block relative top-0.5;
}

.arrow-down:after {
  content: '\25bc';
  color: var(--red);
  @apply ml-2 inline-block relative top-0.5;
}

.yoin-red {
  background-color: #ff5555;
}

.yoin-light-red {

  border-color : rgba(255, 204, 204, 1) 

  
}

.arrow-right:after {
  content: '\25bc';
  transform: rotate(270deg);
  color: var(--yellow);
  @apply ml-2 inline-block relative top-0.5;
}

/* Anchors */
.anchor {
  @apply text-cyan-700;
  @apply hover:text-cyan-500;
}

.anchor-light {
  @apply text-cyan-500;
  @apply hover:text-cyan-300;
}

/* React Calendar Overrides */

.react-calendar {
  border: 0px !important;
}

/* ReactCrop override */
.ReactCrop {
  cursor: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.2444 11.3203H22.8718M11.5581 0.006558V22.634' stroke='%23C1EDCC' stroke-width='2'/%3E%3C/svg%3E")
      12.5 12.5,
    crosshair;
}

/* Custom Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-element {
  animation: fadeIn 3s forwards;
}
